@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Mono:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Red Hat Mono', monospace;
}

.parallax {
    background-image: url("./assets/parallax.jpg");
}

p {
    font-family: monospace;
}

.swal-modal {
    width: 350px;
    opacity: 0;
    pointer-events: none;
    text-align: center;
    font-family: monospace;
    background-color: #0d0b0a;
    border-radius: 10px;
    border: 2px solid #00f1f1;
    position: static;
    margin: 20px auto;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    z-index: 10001;
    transition: opacity .2s,-webkit-transform .3s;
    transition: transform .3s,opacity .2s;
    transition: transform .3s,opacity .2s,-webkit-transform .3s;
}

.swal-button-container {
    margin: 5px;
    display: flex;
    justify-content: center;
    position: relative;
}

.swal-title {
    color: #cc84b7;
    font-weight: 600;
    text-transform: none;
    position: relative;
    display: block;
    padding: 13px 16px;
    font-size: 27px;
    line-height: normal;
    text-align: center;
    margin-bottom: 0;
}

.swal-title:not(:last-child) {
    margin-bottom: 0;
}

.swal-title:first-child {
    margin-top: 0px;
}

.swal-footer {
    text-align: right;
    padding: 13px 16px;
    margin-top: 0;
}

.swal-button {
    background-color: white;
    color: #d59623;
    font-family: monospace;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    padding: 10px 24px;
    margin: 0;
    cursor: pointer;
    transition: 0.2s;
}

.swal-button:not([disabled]):hover {
    background-color: #d59623;
    color: white;
}